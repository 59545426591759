'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.getCurrentProvider = exports.setCurrentProvider = void 0
const types_1 = require('./types')
const CURRENT_PROVIDER_KEY = 'currentProvider'
const setCurrentProvider = (name) => localStorage.setItem(CURRENT_PROVIDER_KEY, name)
exports.setCurrentProvider = setCurrentProvider
const getCurrentProvider = () => {
  const providerNameString = localStorage.getItem(CURRENT_PROVIDER_KEY)
  return providerNameString ? types_1.ProviderName[providerNameString] : null
}
exports.getCurrentProvider = getCurrentProvider
